import { Controller } from "@hotwired/stimulus"
// import { createCustomEvent } from '../utils'

export default class extends Controller {
  static targets = ["add_item", "template", "template2", "select"]
  connect() {
    console.log("nested controller conectado")
  }
  add_association(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML
      .replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
   // this.dispatchAdded()
  }
  add_association2(event) {
    event.preventDefault();
    const content = this.template2Target.innerHTML
      .replace(/TEMPLATE_RECORD2/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
   // this.dispatchAdded()
  }

  remove_association(event) {
    event.preventDefault();
    const item = event.target.closest(".nested-fields");
    
    const tanqueSelectBox = item.querySelector("select[name*='tanque_id']");
    
    if (tanqueSelectBox) {
      console.log("Antes:", tanqueSelectBox.required);  // Deve ser true
      
      tanqueSelectBox.removeAttribute('required');
      
      console.log("Depois:", tanqueSelectBox.required);  // Deve ser false
    }

    item.querySelector("input[id*='_serial']").removeAttribute('required'); // remover required de serial
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
  }

  remove_association_prejob(event) {
    event.preventDefault();
    const item = event.target.closest(".nested-fields")
    const funcao = item.querySelector("select[name*='[funcao1]']")
    const turno = item.querySelector("select[name*='[turno]']")
    const equipe = item.querySelector("select[name*='[equipe]']")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
    funcao.required = false
    turno.required = false
    equipe.required = false
  }

  dispatchAdded() {
    document.dispatchEvent(createCustomEvent('nested:association_added'))
  }

  tipo(event, id) {
    let tipo = event.target.selectedOptions[0].label
    let possui_local = event.target.selectedOptions[0].dataset.possuiLocal
    let possui_serial = event.target.selectedOptions[0].dataset.possuiSerial
    console.log("antes do replace")
    id_input = event.target.id.replace('servico_id', 'tanque_id')
    console.log(id_input)
    console.log("dps do replace")
    console.log("teste")
    
    console.log(event.target.id)
   var serial_input = event.target.id.replace('servico_id', 'serial')
   console.log(serial_input)
   console.log(event.target.id.replace('servico_id', 'serial'))
    var local_input = event.target.id.replace('servico_id', 'local')
   console.log(local_input)
   console.log(event.target.id.replace('servico_id', 'local'))

    if (tipo === 'Diesel' || tipo === 'Void' || tipo === 'Água' || tipo === 'Silo' || tipo === 'Lubrificante' || tipo === 'Fluido' || tipo === 'Etanol' || tipo === "Inspeção de tanques") {
        fetch(`/limpezas/tanques?tipo=${tipo}&ativo_id=${event.params.id}`, {
          credentials: 'same-origin'
        })
        .then(response => response.json())
          .then(data => {
            const selectBox = document.getElementById(id_input)
            selectBox.innerHTML = '';
            const prompt = document.createElement('option');
            prompt.innerHTML = "Selecione";
            prompt.value = ""; // ou prompt.value = null;
            selectBox.required = true;
            
            selectBox.appendChild(prompt);
            data.forEach(item => {
              const opt = document.createElement('option');
              opt.value = item.id;
              opt.innerHTML = item.nome;
              selectBox.appendChild(opt);
            });
          });
          event.target.classList.add('readonly-select');
          // event.target.disabled = true;
    }  else {
      const selectBox = document.getElementById(id_input)
      selectBox.innerHTML = '';
    }
    if (possui_serial === 'true') {

      document.getElementById(serial_input).closest('.col-auto').style.display = 'block';
      document.getElementById(serial_input).required = true;
    } else {
      document.getElementById(serial_input).closest('.col-auto').style.display = 'none';
      document.getElementById(serial_input).required = false;
    }
  
    if (possui_local === 'true') {
      document.getElementById(local_input).closest('.col-auto').style.display = 'block';
    } else {
      document.getElementById(local_input).closest('.col-auto').style.display = 'none';
    }
      
  } 
  
}
